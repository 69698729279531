export const TOGGLE_DARK_MODE = 'toggleDarkMode';
export const SET_LOCALE = 'setLocale';
export const SET_LOGGED_IN_USER = 'setLoggedInUser';
export const TOGGLE_DRAWER_LEFT = 'toggleDrawerLeft';
export const TOGGLE_DRAWER_RIGHT = 'toggleDrawerRight';
export const SET_DRAWER_RIGHT = 'setDrawerRight';
export const SET_DRAWER_LEFT = 'setDrawerLeft';
export const START_LOADING = 'startLoading';
export const STOP_LOADING = 'stopLoading';
export const SELECT_SECTOR = 'selectSector';
export const SET_CURRENT_ITERATION = 'setCurrentIteration';
export const SET_SECTOR_SETS = 'setSectorSets';
export const SET_RECENT_EVENTS = 'setRecentEvents';
export const ADD_TO_RECENT_EVENTS = 'addToRecentEvents';
export const SELECT_SECTOR_SET = 'selectSectorSet';
export const SET_SECTOR_EVENTS = 'setSectorEvents';
export const SET_POINT_OF_INTERESTS = 'setPointOfInterests';
export const SET_POIS_VISIBLE = 'setPoisVisible';
export const SET_ADD_MODE = 'setAddMode';
export const SET_POI_MODAL = 'setPoiModal';
export const SET_ADD_MODE_LONGITUDE = 'setAddModeLongitude';
export const SET_ADD_MODE_LATITUDE = 'setAddModeLatitude';
export const SELECT_POI = 'selectPoi';
export const SET_POINT_OF_INTEREST_CATEGORIES = 'setPointOfInterestCategories';