<template><div /></template>

<script>
export default {
    name: 'StatisticsPage',
    data () {
        return { };
    }
};
</script>

<style></style>
