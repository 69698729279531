import { render, staticRenderFns } from "./MenuRightRegions.vue?vue&type=template&id=cf525a62&scoped=true&"
import script from "./MenuRightRegions.vue?vue&type=script&lang=js&"
export * from "./MenuRightRegions.vue?vue&type=script&lang=js&"
import style0 from "./MenuRightRegions.vue?vue&type=style&index=0&id=cf525a62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf525a62",
  null
  
)

/* custom blocks */
import block0 from "./MenuRightRegions.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCol,VContainer,VProgressLinear,VRow,VSkeletonLoader,VSwitch,VTooltip})
